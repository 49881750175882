import React from "react";
import { Card, Alert, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const Page500 = () => {
  const { state } = useLocation();

  return (
    <div className="login-bg intro-area" style={{ padding: 0 }}>
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <Card className="text-center card-large">
          <Card.Body className="req-border left-form-bg">
            <h4 className="text-green my-4">{"Invalid Request"}</h4>
            <Alert variant="danger">
              {state?.errorMsg || "Something went wrong"}
            </Alert>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Page500;
